/**
 * StatusNotice provides a simple and standard way to display success, notice, error,
 * and informational messages.
 *
 * Usage:
 * var notice = new StatusNotice();
 * // notice.element can be appended to any part of the document.
 * someElement.appendChild(notice.element);
 *
 * // Displaying different types of messages:
 * notice.notice("Yo, something happened");
 * notice.success("Great job!");
 * notice.error("Something went wrong");
 * notice.info("Here's some information");
 *
 * // To add a close button to the notice:
 * someElement.appendChild(notice.element);
 * notice.notice("Yo, something happened");
 * notice.addCloseButton();
 */
export class StatusNotice {
   constructor(element) {
      this.element = element || document.createElement('div');
      if (!element) {
         this.element.className = 'status inPage';
         this.hide();
      }

      this.status = this.element.querySelector('p') || document.createElement('p');
      if (!this.element.contains(this.status)) {
         this.element.append(this.status);
      }
   }

   info(msg) {
      this.update(msg, 'statusInfo');
   }

   notice(msg) {
      this.update(msg, 'statusNotice');
   }

   good(msg) {
      this.update(msg, 'statusSuccess');
   }

   error(msg) {
      this.update(msg, 'statusError');
   }

   update(msg, className) {
      this.status.textContent = msg;
      this.element.className = className + ' inPage status';
      this.element.style.display = 'block';
   }

   hide() {
      this.element.style.display = 'none';
   }

   addCloseButton() {
      const closeButton = document.createElement('i');
      closeButton.className = 'fa fa-times right js-status-cancel';
      closeButton.addEventListener('click', () => this.close());
      this.status.append(closeButton);
   }

   close() {
      this.element.remove();
   }
}
